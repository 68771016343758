<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">Sign In</h1>
				<h5 class="font-regular text-muted">Enter your email and password to sign in</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Email" :colon="false">
						<a-input v-model="email"
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input v-model="password"
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]" type="password" placeholder="Password" />
					</a-form-item>
					<a-form-item class="mb-10">
    					<a-switch v-model="rememberMe" /> Remember Me
					</a-form-item>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							SIGN IN
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

				<v-alert
			      border="right"
			      colored-border
			      type="error"
			      elevation="2"
			      v-if="credentials"
			    >
			      Invalid credentials!
			    </v-alert>

				<!--
				<p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link></p>
			    -->
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
    const axios = require('axios');
    import router from '../router/index.js'
    import store from '../store/store.js'
    import Vue from "vue";    
    import { VueReCaptcha } from "vue-recaptcha-v3";
    //Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITE_KEY_RECAPTCHA });
    Vue.use(VueReCaptcha, { siteKey: "6LdeAeAdAAAAABV0ar1EBPnZa8K66wPtWHrmLR5b" });

	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				rememberMe: true,
				email: "",
                password:"",
                credentials: false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			async handleSubmit(e){
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.recaptcha().then((token) => {
						//console.log('Received values of form: ', values);
					    let url='https://theserverdashboard.info:7200/api/user/v1/sign-in'
                        axios.post(url, {
						    email: this.email,
						    password: this.password
						  })
						  .then((response) => {
						    if(response.status==200){
						      this.credentials = false
                              const token = response.data.token
        					  const email = response.data.email
                              localStorage.setItem('token', token)
                              axios.defaults.headers.common['Authorization'] = token
                              store.commit('auth_success', token, email)
                              router.push('/tables');
						    }
						    else{
						       //console.log(response.status)
						       this.credentials = true
						    }
						  })
						  .catch((error) => {    
						    this.credentials = true
						  });
			          })
			       }
				});
			},
	        async recaptcha() {
		      // (optional) Wait until recaptcha has been loaded.
		      await this.$recaptchaLoaded()

		      // Execute reCAPTCHA with action "login".
		      const token = await this.$recaptcha('login')

		      return token;
	        },        
		},
		template: '<button @click="recaptcha">Execute recaptcha</button>'		       
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>
